/* @font-face {
  font-family: heb;
  src: url("../assets//fonts/Rubik-Regular.ttf") format("truetype");
} */

input[type="time"]:focus {
  outline: none !important;
}

.App {
  direction: ltr;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  color: #3a3c3f;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  background: #f2f2f2;
}

.semi-circle-progress-wrapper
  .semi-circle-progress
  .semi-progress-content
  .semi-progress-content-value {
  font-size: 26px !important;
  margin-top: 0px !important;
}
.semi-circle-progress-wrapper .semi-circle-progress .semi-progress-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 42px !important;
  font-size: 12px !important;
}

.semi-circle-progress-wrapper .semi-circle-progress {
  width: 100% !important;
}
.svg-progress {
  height: 85px !important;
}

@media screen and (max-width: 768px) {
  .App {
    font-size: 14px;
  }
  .svg-progress {
    height: 55px !important;
  }
  .semi-circle-progress-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .semi-progress-content-label {
    font-size: 14px !important;
  }
  .semi-circle-progress-wrapper .semi-circle-progress {
    width: 50% !important;
  }
  .semi-circle-progress-wrapper
    .semi-circle-progress
    .semi-progress-content
    .semi-progress-content-value {
    font-size: 14px !important;
  }
  .semi-circle-progress-wrapper .semi-circle-progress .semi-progress-content {
    margin-top: 27px !important;
  }
}
