body {
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: smooth !important;
}

::selection {
  background: #4a393f6b;
  color: white;
}

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
